<template>
    <a-modal
        :visible="visible"
        :title="state.title"
        @ok="ConversionPoint"
        @cancel="handleModalCancel"
        width="40%"
        :destroy-on-close="true"
        :footer="null">
    
        <AForm
            ref="formRef"
            class="myform"
            :model="form"
            :rules="state.rules"
            @finish="handleOk"
            :wrapper-col="{ span: 14 }"
            :scroll-to-first-error="true">

            <!-- tanggal awal -->
            <ARow class="form-row">
                <ACol :md="24">
                    <AFormItem
                        label="Tanggal Awal"
                        label-align="left"
                        :label-col="{ md: { span: 8 } }"
                        :wrapper-col="{ md: { span: 24 - 8 } }"
                        required
                        name="start_date"
                        has-feedback>
                        <ADatePicker
                            v-model:value="form.start_date"
                            :disabled-date="disabledStartDate"
                            placeholder="tanggal awal"
                            style="width:100%;"
                            format="DD MMMM YYYY"/>
                    </AFormItem>
                </ACol>
            </ARow>

            <!-- tanggal akhir -->
            <ARow class="form-row">
                <ACol :md="24">
                    <AFormItem
                        label="Tanggal Akhir"
                        label-align="left"
                        :label-col="{ md: { span: 8 } }"
                        :wrapper-col="{ md: { span: 24 - 8 } }"
                        required
                        name="end_date"
                        has-feedback>
                        <ADatePicker
                            v-model:value="form.end_date"
                            :disabled-date="disabledEndDate"
                            placeholder="tanggal akhir"
                            style="width:100%;"
                            format="DD MMMM YYYY"/>
                    </AFormItem>
                </ACol>
            </ARow>

            <ARow
                v-if="!form.readOnly"
                class="form-row"
                type="flex"
                justify="end">
                <ACol>
                    <AFormItem>
                        <a-button
                            type="primary"
                            html-type="submit"
                            :loading="form.busy"
                            :disabled="form.busy">{{
                            !form.id ? 'Simpan' : 'Perbarui'
                        }}</a-button>
                    </AFormItem>
                </ACol>
            </ARow>
        </AForm>

        <div v-if="state.errors">
            <ASpace>
                <AAlert
                    v-for="(item, index) in state.errors"
                    type="error"
                    :key="index"
                    :message="item"
                    banner
                    closable
                    @close="state.errors = []" />
            </ASpace>
        </div>
    </a-modal>
</template>
<script>
import {
    defineComponent,
    onMounted,
    reactive,
    ref,
    computed,
} from 'vue'
import Form from 'vform'
import apiClient from '@/services/axios'
import { Modal, message } from 'ant-design-vue'
import moment from 'moment'
import {
    ROLE_ADMIN_BK,
    hasRoles,
} from '@/helpers'

export default defineComponent({
    props: {
        visible: [Boolean],
        item: {
            type: Object,
            default: () => ({
                id: null,
                readOnly: false,
            }),
        },
    },
    emits: ['update:visible'],
    setup(props, { emit }) {
        const form = reactive(new Form({
            id: props.item.id,
            vendor_code: props.item.vendor_code,
            start_date: null,
            end_date: null,
        }))

        const formRef = ref();

        const state = reactive({
            title: 'Lihat History Mapping',
            endpoint: `/api/loyalty-program-stores/${props.item.id}/update-mapping-date`,
            data: [],
            page: 1,
            per_page: 1,
            total: 0,
            loading: false,
            rules: {
                start_date: [
                    {
                        required: true,
                        message: 'Tanggal awal tidak boleh kosong!',
                    },
                ],
                end_date: [
                    {
                        required: true,
                        message: 'Tanggal akhir tidak boleh kosong!',
                    },
                ],
            },
            errors: [],
        })

        const handleModalCancel = () => {
            emit('update:visible', false)
            emit('update:item', null)
        }

        const disabledStartDate = (current) => {
            return current && current > moment(form.end_date)
        }

        const disabledEndDate = (current) => {
            return current && moment(form.start_date) >= current
        }

        const handleOk = async () => {

            // validation form
            await formRef.value.validate()
                .catch(() => {});

            form.start_date = moment(form.start_date).add(1, 'days')
            form.end_date = moment(form.end_date).add(1, 'days')

            form.put(state.endpoint)
                .then(({ data }) => {
                    message.success('Berhasil memperbarui')

                    handleModalCancel()
                    emit('success', data)
                })
                .catch(({ response: { data, status } }) => {
                    if (data.errors) {
                        state.errors = data.errors
                    }
                })

        }

        // handle func vue
        onMounted(() => {
            if (!form.id) {
                form.reset()
            } else {
                form.start_date = moment(props.item.start_date)
                form.end_date = moment(props.item.end_date)
                form.readOnly = props.item.readOnly
            }

            if (!props.item.readOnly) {
                state.title = `${!form.id ? 'Tambah' : 'Edit'} History Mapping`
            }
        })

        return {
            formRef,
            handleOk,
            form,
            state,
            handleModalCancel,
            disabledStartDate,
            disabledEndDate,
            // only variable role
            hasRoles,
            ROLE_ADMIN_BK,
        }
    },
})
</script>

<style lang="scss" scoped>
@import '@/css/form.scss';
</style>
